@media (min-width: 768px) {
  .container {
    width: 768px;
  }
}

@media (min-width: 900px) {
  .container {
    width: 900px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1200px;
  }
}

@media (min-width: 1400px) {
  .container {
    width: 1400px;
  }
}

@media (max-width: 767px) {
  .container {
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .absolute.absolute-static {
    position: static;
  }
  .fixed.fixed-static {
    position: static;
  }
  .absolute-i.absolute-static {
    position: static !important;
  }
  .fixed-i.fixed-static {
    position: static !important;
  }
  h1, .h1 {
    font-size: 42px;
    line-height: 50px;
  }
  h2, .h2 {
    font-size: 36px;
    line-height: 44px;
  }
  h3, .h3 {
    font-size: 30px;
    line-height: 38px;
  }
  h4, .h4 {
    font-size: 24px;
    line-height: 32px;
  }
  h5, .h5 {
    font-size: 18px;
    line-height: 26px;
  }
  h6, .h6 {
    font-size: 12px;
    line-height: 20px;
  }
}

@media (max-width: 767px) {
  .alert {
    width: 100%;
    padding: 12px;
  }
  .alert[data-placement$="-center"] {
    right: auto;
    left: 0;
    margin-left: 0;
  }
  .alert > .alert-id {
    max-width: 100%;
    width: 100%;
    min-width: 100%;
    padding: 12px;
    margin: auto auto 12px auto;
  }
  .alert > .alert-id > .alert-footer {
    padding-top: 12px;
    grid-template-columns: calc(100% - 100px - 12px) 100px;
    grid-gap: 12px;
  }
}

@media (max-width: 479px) {
  .alert > .alert-id > .alert-footer {
    grid-template-columns: auto auto;
  }
}

@media (max-width: 319px) {
  .alert > .alert-id > .alert-footer {
    grid-template-columns: 100%;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .btn {
    display: block;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .confirm {
    padding: 12px;
  }
  .confirm > .confirm-id {
    width: 100%;
    min-width: 100%;
    padding: 12px;
  }
  .confirm > .confirm-id > .confirm-footer {
    padding: 0;
  }
  .confirm > .confirm-id > .confirm-footer > .btn {
    margin-left: 0;
  }
}

@media (max-width: 479px) {
  .datepicker > .datepicker-wrapper > .datepicker-block {
    width: 100%;
  }
  .datepicker > .datepicker-wrapper > .datepicker-block > .block-date > .middle > .daylist {
    grid-template-columns: repeat(auto-fill, minmax(36px, 1fr));
  }
}

@media (max-width: 319px) {
  .datepicker > .datepicker-wrapper > .datepicker-block > .block-date > .top,
  .datepicker > .datepicker-wrapper > .datepicker-block[data-datepicker-type="datetime"] > .block-date > .footer {
    grid-gap: 8px;
    grid-template-columns: 100%;
  }
}

@media (max-width: 899px) {
  .row {
    display: block;
    width: 100%;
  }
  .row > .col-1 {
    display: block;
    width: 100%;
  }
  .row > .col-2 {
    display: block;
    width: 100%;
  }
  .row > .col-3 {
    display: block;
    width: 100%;
  }
  .row > .col-4 {
    display: block;
    width: 100%;
  }
  .row > .col-5 {
    display: block;
    width: 100%;
  }
  .row > .col-6 {
    display: block;
    width: 100%;
  }
  .row > .col-7 {
    display: block;
    width: 100%;
  }
  .row > .col-8 {
    display: block;
    width: 100%;
  }
  .row > .col-9 {
    display: block;
    width: 100%;
  }
  .row > .col-10 {
    display: block;
    width: 100%;
  }
  .row > .col-11 {
    display: block;
    width: 100%;
  }
  .row > .col-12 {
    display: block;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .columns-1 {
    grid-template-columns: 100%;
  }
  .columns-2 {
    grid-template-columns: 100%;
  }
  .columns-3 {
    grid-template-columns: 100%;
  }
  .columns-4 {
    grid-template-columns: 100%;
  }
  .columns-5 {
    grid-template-columns: 100%;
  }
  .columns-6 {
    grid-template-columns: 100%;
  }
  .columns-7 {
    grid-template-columns: 100%;
  }
  .columns-8 {
    grid-template-columns: 100%;
  }
  .columns-9 {
    grid-template-columns: 100%;
  }
  .columns-10 {
    grid-template-columns: 100%;
  }
  .columns-11 {
    grid-template-columns: 100%;
  }
  .columns-12 {
    grid-template-columns: 100%;
  }
  .column-1 {
    grid-column: unset;
  }
  .column-1-2 {
    grid-column: unset;
  }
  .column-1-3 {
    grid-column: unset;
  }
  .column-1-4 {
    grid-column: unset;
  }
  .column-1-5 {
    grid-column: unset;
  }
  .column-1-6 {
    grid-column: unset;
  }
  .column-1-7 {
    grid-column: unset;
  }
  .column-1-8 {
    grid-column: unset;
  }
  .column-1-9 {
    grid-column: unset;
  }
  .column-1-10 {
    grid-column: unset;
  }
  .column-1-11 {
    grid-column: unset;
  }
  .column-1-12 {
    grid-column: unset;
  }
}

@media (max-width: 767px) {
  .modal {
    padding: 12px;
  }
  .modal > .wrapper {
    width: 100%;
    padding: 0 12px;
    height: calc(100vh - 24px);
  }
  .modal > .wrapper > .modal-content > .modal-footer {
    padding-top: 12px;
  }
}

@media (max-width: 899px) {
  .navbar {
    height: auto;
  }
  .navbar .navbar-wrapper {
    display: block;
  }
  .navbar .navbar-wrapper > .nav-center,
  .navbar .navbar-wrapper > .nav-right {
    display: none;
  }
  .navbar.open .navbar-wrapper > .nav-center,
  .navbar.open .navbar-wrapper > .nav-right,
  .navbar .navbar-wrapper > .nav-left,
  .navbar .navbar-wrapper .navbar-menu,
  .navbar .navbar-wrapper .nav-item,
  .navbar .navbar-wrapper .nav-link,
  .navbar .navbar-wrapper .navbar-menu > li {
    display: block;
    width: 100%;
  }
  .navbar .navbar-wrapper > .nav-center,
  .navbar .navbar-wrapper > .nav-right {
    padding: 20px;
  }
  .navbar .navbar-wrapper > .nav-right {
    padding-top: 0;
  }
  .navbar .navbar-wrapper > .nav-center,
  .navbar .navbar-wrapper .navbar-menu {
    padding-bottom: 0;
  }
  .navbar .navbar-wrapper .nav-mobile {
    display: inline-block;
  }
  .navbar .navbar-wrapper .nav-submenu {
    position: static;
    width: 100%;
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.04);
  }
  .navbar .navbar-wrapper .nav-submenu .nav-item,
  .navbar .navbar-wrapper .nav-submenu .nav-link {
    color: #757575;
  }
  .navbar .navbar-wrapper .nav-item,
  .navbar .navbar-wrapper .nav-link {
    line-height: 63px;
    height: 64px;
    padding: 0 20px;
  }
  .navbar .navbar-wrapper .nav-link:after {
    display: none;
  }
  .navbar .navbar-wrapper .navbar-menu > li > .nav-submenu:before {
    display: none;
  }
  .navbar.open .navbar-wrapper .nav-mobile,
  .navbar .navbar-wrapper .nav-mobile:hover,
  .navbar .navbar-wrapper .nav-mobile:active,
  .navbar .navbar-wrapper .nav-link:active,
  .navbar .navbar-wrapper .nav-link.active,
  .navbar .navbar-wrapper .nav-link:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  .navbar.open.navbar-text-light .navbar-wrapper .nav-mobile,
  .navbar.navbar-text-light .navbar-wrapper .nav-mobile:hover,
  .navbar.navbar-text-light .navbar-wrapper .nav-mobile:active,
  .navbar.navbar-text-light .navbar-wrapper .nav-link:hover,
  .navbar.navbar-text-light .navbar-wrapper .nav-link:active,
  .navbar.navbar-text-light .navbar-wrapper .nav-link.active {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .navbar.navbar-text-light .navbar-wrapper .nav-submenu .nav-link:hover,
  .navbar.navbar-text-light .navbar-wrapper .nav-submenu .nav-link:active,
  .navbar.navbar-text-light .navbar-wrapper .nav-submenu .nav-link.active {
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
  }
}

@media (max-width: 767px) {
  .navbar .navbar-wrapper > .nav-center,
  .navbar .navbar-wrapper > .nav-right {
    padding: 12px;
  }
  .navbar .navbar-wrapper .nav-item,
  .navbar .navbar-wrapper .nav-link {
    padding: 0 12px;
  }
}

@media (max-width: 479px) {
  .navbar .navbar-wrapper .nav-brand {
    font-size: 20px;
  }
}

@media (max-width: 319px) {
  .navbar .navbar-wrapper .nav-brand {
    display: none;
  }
  .navbar .navbar-wrapper {
    padding-top: 80px;
  }
  .navbar .navbar-wrapper .nav-mobile {
    width: 80px;
    position: absolute;
    top: 0;
    right: 0;
  }
}

@media (max-width: 899px) {
  .nav-menu.nav-menu-responsive {
    width: 64px;
  }
  .nav-menu.nav-menu-responsive .nav-menu-item.active {
    background-color: rgba(0, 0, 0, 0.08);
  }
  .nav-menu.nav-menu-responsive .nav-menu-link .link-text {
    position: absolute;
    left: 100%;
    top: 0;
    z-index: 1;
    max-width: calc(100vw - 84px);
    padding: 20px;
    visibility: hidden;
    white-space: nowrap;
    display: block;
    opacity: 0;
    background-color: #eee;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  }
  .nav-menu.nav-menu-responsive .nav-menu-link:hover .link-text {
    visibility: visible;
    opacity: 1;
  }
  .nav-menu.nav-menu-responsive .nav-menu-link .link-icon {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .nav-menu.nav-menu-responsive .nav-menu-link .link-text {
    padding: 12px;
  }
}

@media (max-width: 319px) {
  .nav-menu.nav-menu-responsive .nav-menu-link .link-text {
    width: calc(100vw - 84px);
  }
  .nav-menu.nav-menu-responsive .nav-menu-link .link-text {
    word-break: normal;
    white-space: normal;
  }
}

@media (max-width: 1199px) {
  .table.table-responsive,
  .table.table-responsive .table-row,
  .table.table-responsive .table-body,
  .table.table-responsive .table-footer,
  .table.table-responsive .table-column,
  .table.table-responsive .table-col-group,
  .table.table-responsive .table-cell,
  .table.table-responsive .table-caption,
  .table.table-responsive .table-cell-resp {
    display: block;
  }
  .table.table-responsive .table-cell {
    text-align: left;
  }
  .table.table-responsive .table-header {
    display: none;
  }
  .table.table-responsive .table-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    -ms-flex-align: start;
        align-items: start;
    border-top: 1px solid #eee;
    margin-bottom: 20px;
  }
  .table.table-responsive .table-row:last-child {
    border-bottom: 1px solid #eee;
  }
  .table.table-responsive .table-row:last-child {
    margin: 0;
  }
  .table.table-responsive .table-row > .table-cell:last-child {
    text-align: left;
  }
}

@media (max-width: 767px) {
  .tabs > .tab-links {
    padding: 8px;
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 8px;
  }
  .tabs > .tab-links > li {
    display: block;
  }
  .tabs > .tab-links > li > .tab-link {
    line-height: 40px;
    height: 40px;
    min-width: 40px;
    padding: 0 12px;
    width: 100%;
    display: block;
  }
  .tabs > .tab-links > li > .tab-link:hover,
  .tabs > .tab-links > li > .tab-link.active {
    border: 0;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .tabs.tabs-horizontal {
    grid-template-columns: 100%;
  }
  .tabs > .tab-links > li .tab-link:hover,
  .tabs > .tab-links > li .tab-link.active {
    border: 0;
  }
}

@media (min-width: 320px) {
  .breakpoint-phone-from {
    width: 100%;
    display: block;
    grid-template-columns: 100%;
    grid-column: unset;
  }
  .breakpoint-phone-from-i {
    width: 100% !important;
    display: block !important;
    grid-template-columns: 100% !important;
    grid-column: unset !important;
  }
}

@media (min-width: 480px) {
  .breakpoint-smartphone-from {
    width: 100%;
    display: block;
    grid-template-columns: 100%;
    grid-column: unset;
  }
  .breakpoint-smartphone-from-i {
    width: 100% !important;
    display: block !important;
    grid-template-columns: 100% !important;
    grid-column: unset !important;
  }
}

@media (min-width: 768px) {
  .breakpoint-tablet-from {
    width: 100%;
    display: block;
    grid-template-columns: 100%;
    grid-column: unset;
  }
  .breakpoint-tablet-from-i {
    width: 100% !important;
    display: block !important;
    grid-template-columns: 100% !important;
    grid-column: unset !important;
  }
}

@media (min-width: 900px) {
  .breakpoint-tablet-fullscreen-from {
    width: 100%;
    display: block;
    grid-template-columns: 100%;
    grid-column: unset;
  }
  .breakpoint-tablet-fullscreen-from-i {
    width: 100% !important;
    display: block !important;
    grid-template-columns: 100% !important;
    grid-column: unset !important;
  }
}

@media (min-width: 1200px) {
  .breakpoint-desktop-from {
    width: 100%;
    display: block;
    grid-template-columns: 100%;
    grid-column: unset;
  }
  .breakpoint-desktop-from-i {
    width: 100% !important;
    display: block !important;
    grid-template-columns: 100% !important;
    grid-column: unset !important;
  }
}

@media (min-width: 1400px) {
  .breakpoint-desktop-fullscreen-from {
    width: 100%;
    display: block;
    grid-template-columns: 100%;
    grid-column: unset;
  }
  .breakpoint-desktop-fullscreen-from-i {
    width: 100% !important;
    display: block !important;
    grid-template-columns: 100% !important;
    grid-column: unset !important;
  }
}

@media (width: 320px) {
  .breakpoint-phone {
    width: 100%;
    display: block;
    grid-template-columns: 100%;
    grid-column: unset;
  }
  .breakpoint-phone-i {
    width: 100% !important;
    display: block !important;
    grid-template-columns: 100% !important;
    grid-column: unset !important;
  }
}

@media (width: 480px) {
  .breakpoint-smartphone {
    width: 100%;
    display: block;
    grid-template-columns: 100%;
    grid-column: unset;
  }
  .breakpoint-smartphone-i {
    width: 100% !important;
    display: block !important;
    grid-template-columns: 100% !important;
    grid-column: unset !important;
  }
}

@media (width: 768px) {
  .breakpoint-tablet {
    width: 100%;
    display: block;
    grid-template-columns: 100%;
    grid-column: unset;
  }
  .breakpoint-tablet-i {
    width: 100% !important;
    display: block !important;
    grid-template-columns: 100% !important;
    grid-column: unset !important;
  }
}

@media (width: 900px) {
  .breakpoint-tablet-fullscreen {
    width: 100%;
    display: block;
    grid-template-columns: 100%;
    grid-column: unset;
  }
  .breakpoint-tablet-fullscreen-i {
    width: 100% !important;
    display: block !important;
    grid-template-columns: 100% !important;
    grid-column: unset !important;
  }
}

@media (width: 1200px) {
  .breakpoint-desktop {
    width: 100%;
    display: block;
    grid-template-columns: 100%;
    grid-column: unset;
  }
  .breakpoint-desktop-i {
    width: 100% !important;
    display: block !important;
    grid-template-columns: 100% !important;
    grid-column: unset !important;
  }
}

@media (width: 1400px) {
  .breakpoint-desktop-fullscreen {
    width: 100%;
    display: block;
    grid-template-columns: 100%;
    grid-column: unset;
  }
  .breakpoint-desktop-fullscreen-i {
    width: 100% !important;
    display: block !important;
    grid-template-columns: 100% !important;
    grid-column: unset !important;
  }
}

@media (max-width: 1399px) {
  .breakpoint-desktop-fullscreen-to {
    width: 100%;
    display: block;
    grid-template-columns: 100%;
    grid-column: unset;
  }
  .breakpoint-desktop-fullscreen-to-i {
    width: 100% !important;
    display: block !important;
    grid-template-columns: 100% !important;
    grid-column: unset !important;
  }
}

@media (max-width: 1199px) {
  .breakpoint-desktop-to {
    width: 100%;
    display: block;
    grid-template-columns: 100%;
    grid-column: unset;
  }
  .breakpoint-desktop-to-i {
    width: 100% !important;
    display: block !important;
    grid-template-columns: 100% !important;
    grid-column: unset !important;
  }
}

@media (max-width: 899px) {
  .breakpoint-tablet-fullscreen-to {
    width: 100%;
    display: block;
    grid-template-columns: 100%;
    grid-column: unset;
  }
  .breakpoint-tablet-fullscreen-to-i {
    width: 100% !important;
    display: block !important;
    grid-template-columns: 100% !important;
    grid-column: unset !important;
  }
}

@media (max-width: 767px) {
  .breakpoint-tablet-to {
    width: 100%;
    display: block;
    grid-template-columns: 100%;
    grid-column: unset;
  }
  .breakpoint-tablet-to-i {
    width: 100% !important;
    display: block !important;
    grid-template-columns: 100% !important;
    grid-column: unset !important;
  }
}

@media (max-width: 479px) {
  .breakpoint-smartphone-to {
    width: 100%;
    display: block;
    grid-template-columns: 100%;
    grid-column: unset;
  }
  .breakpoint-smartphone-to-i {
    width: 100% !important;
    display: block !important;
    grid-template-columns: 100% !important;
    grid-column: unset !important;
  }
}

@media (max-width: 319px) {
  .breakpoint-phone-to {
    width: 100%;
    display: block;
    grid-template-columns: 100%;
    grid-column: unset;
  }
  .breakpoint-phone-to-i {
    width: 100% !important;
    display: block !important;
    grid-template-columns: 100% !important;
    grid-column: unset !important;
  }
}
